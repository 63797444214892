import {
  ApolloClient
} from 'apollo-client';
import {
  InMemoryCache
} from 'apollo-cache-inmemory';
import {
  createUploadLink
} from 'apollo-upload-client';
import {
  onError
} from 'apollo-link-error';
import {
  setContext
} from 'apollo-link-context';
import {
  ApolloLink
} from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import _ from 'lodash';
import qs from 'qs';

import {
  MutationResolver,
  QueryResolver
} from './local-resolvers';
import {
  GET_USER
} from '../Routes/queries';

import initialBaseParams from '../../data/initialBaseParams';
import mapActiveModuleToDefaultParams from '../../data/mapActiveModuleToDefaultParams';
import mapModuleToPathname from '../../data/mapModuleToPathname';

import parseQueryParamsToState from '../../helpers/parseQueryParamsToState';

const cache = new InMemoryCache();

let urlQueryParams = {};

if (window.location.search) {
  const queryString = window.location.search.replace('?', '');
  const queryObject = qs.parse(queryString, { comma: true });

  if (queryObject) {
    urlQueryParams = parseQueryParamsToState(queryObject);
  }
}

const baseParams = _.get(urlQueryParams, 'baseParams');
urlQueryParams = _.omit(urlQueryParams, 'baseParams');

const pathname = window.location.pathname;
const moduleKey = mapModuleToPathname[pathname];
const initialModulePathnameParams = mapActiveModuleToDefaultParams[moduleKey] || {};

cache.writeData({
  data: {
    app: {
      __typename: 'AppState',
      activeBaseMap: 9,
      activeLayers: [],
      layersOpacity: 70,
      activeModule: 'coverage',
      activeModuleContent: 'coverage:coverage_main',
      activeYear: 2022,
      headerIsVisible: true,
      mapFlyTo: null,
      mapPointInfo: null,
      mapPosition: '-14.392118,-56.250000,4',
      mapboxMode: false,
      showClassInfo: null,
      showDashboardInfo: null,
      showBeforeAndAfterMosaic: false,
      timelineLimitsRange: [2000, 2022],
      ruralPropertyCode: null,
      timelineSpecificValues: null,
      baseParams: {
        __typename: 'AppBaseParamsState',
        ...initialBaseParams,
        ...(baseParams || {})
      },
      ...urlQueryParams,
      isLoadingBaseData: false,
      notifyUserNewTermOfUseVersion: false,
      transitionClassTreeNodeId: null,
      activeNoteKey: null,
      ...initialModulePathnameParams
    },
    toast: {
      __typename: 'ToastState',
      content: null,
      severity: null,
      visible: false
    }
  }
});

const logErrors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        '[GraphQL error]: Message: ',
        message,
        ', Location: ',
        locations,
        ', Path: ',
        path
      )
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const uploadLink = createUploadLink({
  uri: '/app/graphql'
});

const withToken = setContext((_, previousContext) => {
  let token = localStorage.getItem('token');
  if (token === null) {
    return previousContext;
  }

  return {
    ...previousContext,
    headers: {
      ...(previousContext && previousContext.headers),
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const resetTokenOnUnauthorized = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors && _.some(graphQLErrors, (e) => e.extensions && e.extensions.forbidden)) {
    if (operation.operationName !== _.get(GET_USER, 'definitions[0].name.value')) {
      // So we don't reset the store when it's not necessary
      if (localStorage.getItem('token')) {
        client.resetStore();
      }
    }
    localStorage.removeItem('token');
  }
});

const link = new HttpLink({
  uri: '/api/graphql',
});

const client = new ApolloClient({
  cache,
  link,
  // link: ApolloLink.from(_.compact([
  //   withToken,
  //   resetTokenOnUnauthorized,
  //   process.env.NODE_ENV === 'development' ? logErrors : null,
  //   uploadLink
  // ])),
  resolvers: { Query: QueryResolver, Mutation: MutationResolver }
})

export default client;
