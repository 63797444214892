export default {
  groupType: 'territorial',
  territoryType: null,
  territorySubtype: null,
  territoryValueType: 'single',
  crossingEnabled: false,
  crossingTerritoryType: null,
  crossingTerritories: null,
  territory: null,
  territories: null,
  crossing: 'none',
  classesViewMode: 'class',
  activeClassTreeOptionValue: null,
  activeClassTreeNodeIds: [],
  activeObjectTreeNodeIds: [],
  activeSubmodule: null,
  activeClassesLevelsListItems: null,
  activeTransitionsClassesListItems: null,
  activePastureQualityQualityClassesListItems: null,
  yearRange: '2000-2022',
  buffer: 0,
}
